var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.products,"items-per-page":10,"loading":_vm.loading,"loading-text":"Carregando dados","sort-by":"name","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Produtos")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"400px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"primary"},'v-btn',attrs,false),on),[_vm._v("Novo")])]}}]),model:{value:(_vm.newProductDialog),callback:function ($$v) {_vm.newProductDialog=$$v},expression:"newProductDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Cadastro de produto")])]),_c('v-card-text',[_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s(_vm.erros)+" ")]),_c('v-text-field',{attrs:{"label":"Produto","required":"","outlined":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-select',{attrs:{"label":"Marca","items":_vm.brands,"item-text":"name","item-value":"id","required":"","outlined":""},model:{value:(_vm.brand_id),callback:function ($$v) {_vm.brand_id=$$v},expression:"brand_id"}}),_c('v-select',{attrs:{"label":"Categoria","items":_vm.categories,"item-text":"category","item-value":"id","required":"","outlined":""},model:{value:(_vm.category_id),callback:function ($$v) {_vm.category_id=$$v},expression:"category_id"}}),_c('v-textarea',{attrs:{"label":"Descrição","required":"","outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-text-field',{attrs:{"label":"Valor","prefix":"R$","outlined":""},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}}),_c('v-file-input',{attrs:{"accept":"image/*","label":"Foto","required":"","outlined":"","show-size":""},model:{value:(_vm.photo),callback:function ($$v) {_vm.photo=$$v},expression:"photo"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"red--text",attrs:{"text":""},on:{"click":function($event){_vm.newProductDialog = false}}},[_vm._v(" Cancelar ")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"text":""},on:{"click":_vm.newProduct}},[_vm._v("Gravar")])],1)],1)],1)],1)]},proxy:true},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(parseFloat(item.price).toLocaleString("pt-BR", { style: "currency", currency: "BRL", }))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2 orange--text",attrs:{"small":""},on:{"click":function($event){return _vm.showUpdateDialog(item)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{staticClass:"red--text",attrs:{"small":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" mdi-delete ")])]}}])}),_c('v-dialog',{attrs:{"max-width":"400px","persistent":""},model:{value:(_vm.updateProductDialog),callback:function ($$v) {_vm.updateProductDialog=$$v},expression:"updateProductDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("Atualizar de produto")])]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":"Produto","required":"","outlined":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-select',{attrs:{"label":"Marca","items":_vm.brands,"item-text":"name","item-value":"id","required":"","outlined":""},model:{value:(_vm.brand_id),callback:function ($$v) {_vm.brand_id=$$v},expression:"brand_id"}}),_c('v-select',{attrs:{"label":"Categoria","items":_vm.categories,"item-text":"category","item-value":"id","required":"","outlined":""},model:{value:(_vm.category_id),callback:function ($$v) {_vm.category_id=$$v},expression:"category_id"}}),_c('v-textarea',{attrs:{"label":"Descrição","required":"","outlined":""},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('v-text-field',{attrs:{"label":"Valor","prefix":"R$","outlined":""},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1),_c('v-card-actions',[_c('v-btn',{staticClass:"red--text",attrs:{"text":""},on:{"click":_vm.cancelUpdateDialog}},[_vm._v(" Cancelar ")]),_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"text":""},on:{"click":_vm.updateProduct}},[_vm._v("Gravar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }